.navigation-menu {
	color: var(--navbar-color);
	position: absolute;
	width: 400px;
	height: 100%;
	background: white;
	z-index: 50;
	top: 0px;
}

.navigation-menu-close {
	float: left;
	border-radius: 25px;
	border: none;
	padding: 16px;
	padding-top: 0px;
	padding-bottom: 0px;
	cursor: pointer;
	color: var(--navbar-color);
	font-size: 40px;
}

.navigation-menu-content {
	text-align: left;
	margin-left: 50px;
	margin-top: 100px;
}

.navigation-menu-kitchen-icon {
/*	position: absolute;
	top: 100px;
	left: 50px;*/
	position: relative;
	top: 5px;
}

.navigation-menu-kitchen-label {
	font-size: 20px;
	font-weight: 600;
	margin-left: 30px;
	display: inline-block;
	color: var(--secondary-color);
/*	position: absolute;
	left: 60px;
	top: 100px;*/
}

.navigation-menu-admin-icon {
/*	position: absolute;
	top: 160px;
	left: 50px;*/
	position: relative;
	top: 5px;
}

.navigation-menu-admin-label {
	font-size: 20px;
	font-weight: 600;
	margin-left: 30px;
	display: inline-block;
	color: var(--secondary-color);
/*	position: absolute;
	left: 60px;
	top: 160px;*/
}

.navigation-menu-link {
	display: block;
	width: 100%;
	height: 30px;
	color: var(--secondary-color);
	margin-bottom: 30px;
}