.orders-generic {
	float: left;
	width: 50%;
	text-align: left;
	padding-left: 30px;
	padding-right: 30px;
	box-sizing: border-box;
	height: 308px;
	border-right: solid 1px var(--border-color);
	z-index: 2;
}

.orders-generic-header {
	color: var(--navbar-color);
	margin-top: 25px;
	font-size: 28px;
	font-weight: 600;
}

.order-card-order-number {
	color: var(--navbar-color);
}

.order-containers-spacer {
	height: 30px;
	width: 100%;
	color: black;
}

.order-containers {
	overflow-y: scroll;
	top: 302px;
	bottom: 0px;
	position: absolute;
	border-right: solid 1px var(--border-color);
	width: calc(50% - 42px);
	z-index: 1;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    padding-right: 11px;
}

.order-containers::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

/*.order-container.parent {
	overflow-y: scroll;
	max-height: 100%;
	position: absolute;
	top: 0px;
}*/

.order-container {
	margin-bottom: 25px;
	width: 100%;
}

.order-card-spacer {
	height: 100px;
}

.order-card-placeholder {
	text-align: center;
	margin-top: 100px;
}