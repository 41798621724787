.orders-aggregated {
	float: right;
	width: 50%;
	text-align: left;
	padding-left: 30px;
	padding-right: 30px;
	box-sizing: border-box;
	height: 308px;
}

.orders-aggregated-header {
	color: var(--navbar-color);
	margin-top: 25px;
	font-size: 28px;
	font-weight: 600;
}

.orders-aggregated-containers-spacer {
	height: 30px;
	width: 100%;
	color: black;
}

.orders-aggregated-containers {
	overflow-y: scroll;
	top: 302px;
	padding-top: -30px;
	bottom: 0px;
	position: absolute;
	width: calc(50% - 64px);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.orders-aggregated-containers::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.orders-aggregated-container {
	margin-bottom: 25px;
	width: 100%;
}

.orders-aggregated-card-spacer {
	height: 100px;
}

.orders-aggregated-card-placeholder {
	text-align: center;
	margin-top: 100px;
}