.horizontal-selector-orders-wide {
	color: white;
	background: var(--navbar-color);
	border-radius: 8px;
	margin-top: 30px;
	margin-bottom: 0px;
	padding: 5px;
	box-sizing: border-box;
	width: fit-content;
	font-size: 16px;
	font-weight: 600;
}

.horizontal-selector-orders-narrow {
	color: white;
	background: var(--navbar-color);
	border-radius: 8px;
	margin-top: 30px;
	margin-bottom: 0px;
	padding: 5px;
	box-sizing: border-box;
	width: fit-content;
	font-size: 13px;
	font-weight: 600;
}

.horizontal-selector-orders-label {
	padding-top: 7px;
	padding-bottom: 7px;
	padding-left: 10px;
	padding-right: 10px;
	display: inline-block;
	border-radius: 8px;
}

.active {
	background: white;
	color: var(--navbar-color);
}